// if (window._rails_loaded) throw 'already loaded';
// import "../stylesheets/howmuch/howmuch";
//require '../shared/spree/frontend'

import $ from "../shared/jquery-global";

import RailsUjs from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails"
Turbo.setFormMode('optin')

import "../shared/spree/frontend";
import "sticky-kit/dist/sticky-kit";
import LocalTime from "local-time";
import "../shared/set-timezone";
import "../shared/set-csrf";

import "../shared/frontend";
import "../shared/cloudinary";
//require './backend'
import CollapsibleLists from "collapsible-lists/CollapsibleLists.src";

//require('../howmuch/styles')
import "smartbanner.js/dist/smartbanner";
import "@materializecss/materialize/dist/js/materialize";
import { initializeSliderCarousel } from "../shared/common";
import { documentOn, elementsOnEvent } from "../shared/jquery-alternative";
import { setCsrfTokenFromCookie } from "../shared/set-csrf";

LocalTime.start();
document.addEventListener("DOMContentLoaded", () => {
  if(document.querySelector("form[data-remote=true]")) {
    RailsUjs.start(); //TOOD Added back, as its required for: http://127.0.0.1:3000/stores/Sauda-Sulf-F11/checkout/page. Was previously creating issues maybe?
  }  
})

document.addEventListener("turbo:load", function() {
  setCsrfTokenFromCookie();
  RailsUjs.refreshCSRFTokens();
  let elems = document.querySelectorAll(".sidenav");
  const instances = M.Sidenav.init(elems, {});

  M.updateTextFields();

  if(document.querySelectorAll(".tabs").length > 0) {
    M.Tabs.init(document.querySelectorAll(".tabs"), {});
  }

  elems = document.querySelectorAll(".btnMenuToggle");
  elems.forEach(elem => {
    elem.onclick = function(e) {
      const target = this.getAttribute("data-target");
      const elem = document.querySelector(`#${ target }`);
      const instance = M.Sidenav.getInstance(elem);
      if (instance.isOpen) {
        setTimeout(() => instance.close(), 50);
      }
    };
  });

  if(document.querySelectorAll(".products-carousel").length > 0) {
    M.Carousel.init(document.querySelectorAll(".products-carousel"));
  }
  initializeSliderCarousel();

  // M.AutoInit();

  M.FormSelect.init(document.querySelectorAll("select"), {});
});

document.addEventListener("turbo:load", function() {
  $("#order-summary-box").stick_in_parent({
    offset_top: 80,
  });
  //TODO fix CollapsibleLists collapsible conflict with materialzie
  if (
    document.querySelectorAll(".cart-orders .collapsible").length > 0 &&
    !!$(".cart-orders .collapsible").collapsible
  ) {
    $(".cart-orders .collapsible").collapsible({
      onOpen(el) {
        // Callback for Collapsible open
        return el
          .querySelector(".material-icons.cart-arrow-icon")
          .innerHTML = "keyboard_arrow_down";
      },
      onClose(el) {
        // Callback for Collapsible close
        return el
          .querySelector(".material-icons.cart-arrow-icon")
          .innerHTML = "keyboard_arrow_right";
      },
    });
  }

  documentOn("click", "#card-alert a", function() {
    $(this).parents("#card-alert").fadeOut("slow");
  });

  elementsOnEvent(".cat-menu > li a", "click", function() {
    $(this).parent().find("ul").toggle();
  });
  CollapsibleLists.apply();
});
